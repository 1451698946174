@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Ruluko&display=swap");

::-webkit-scrollbar {
    display: none;
}

html,
body {
    all: unset;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html:focus-within {
    scroll-behavior: smooth;
}

@font-face {
    font-family: "Crochet";
    src: url("./lib/Fonts/Crochet.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
@font-face {
    font-family: "Motion Picture";
    src: url("./lib/Fonts/MotionPicture.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
